import React from "react";
import ServiciuTop from "../components/serviciu-top/serviciu-top";
import ContactMap from "../components/contact-map/contact-map";
import { Helmet } from "react-helmet";

const DocumentatieDeDezmembrare = () => (
  <>
    <Helmet>
      <title>Documentație de Dezmembrare - Servicii Profesionale în Prahova</title>
      <meta
        name="description"
        content="Obține documentația necesară pentru dezmembrarea imobilelor cu ajutorul echipei Topolex din Vălenii de Munte. Servicii rapide și eficiente în județul Prahova."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta
        name="keywords"
        content="documentație de dezmembrare, dezmembrare imobile, cadastru Prahova, servicii cadastrale Vălenii de Munte, soluții cadastrale rapide"
      />
      <meta name="author" content="Topolex" />

      {/* Open Graph Meta Tags */}
      <meta
        property="og:title"
        content="Documentație de Dezmembrare - Servicii Profesionale în Prahova"
      />
      <meta
        property="og:description"
        content="Topolex oferă servicii profesionale pentru documentația de dezmembrare a imobilelor în județul Prahova."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://topolex.ro/documentatie-de-dezmembrare"
      />
      <meta property="og:image" content="https://topolex.ro/assets/banner.webp" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Documentație de Dezmembrare - Servicii Profesionale în Prahova"
      />
      <meta
        name="twitter:description"
        content="Topolex oferă soluții rapide și eficiente pentru dezmembrarea imobilelor în Vălenii de Munte, Prahova."
      />
      <meta name="twitter:image" content="https://topolex.ro/assets/banner.webp" />
      <link
        rel="canonical"
        href="https://topolex.ro/documentatie-de-dezmembrare"
      />
    </Helmet>

    <ServiciuTop
      title="Documentație de Dezmembrare"
      text="Topolex oferă servicii complete pentru întocmirea documentației de dezmembrare a imobilelor. Cu o echipă de experți în cadastru și topografie, ne asigurăm că procesul este rapid, precis și conform reglementărilor legale."
    />
    <section className="serviciu-content">
      <div className="container">
        <h2>Ce este dezmembrarea unui imobil?</h2>
        <p>
          Dezmembrarea unui imobil reprezintă procesul de divizare a unei proprietăți în două sau mai multe părți distincte, fiecare având un număr cadastral propriu și fiind înregistrată separat în Cartea Funciară. Acest proces este esențial în cazul vânzării unei părți din teren sau al transferului drepturilor asupra unei secțiuni a proprietății.
        </p>

        <h2>Când este necesară dezmembrarea?</h2>
        <ul>
          <li>În cazul partajului sau succesiunii imobilelor;</li>
          <li>Pentru vânzarea unei părți dintr-un teren;</li>
          <li>În scopuri de dezvoltare imobiliară;</li>
          <li>Pentru delimitarea proprietății între coproprietari.</li>
        </ul>

        <h2>De ce să alegi Topolex pentru documentația de dezmembrare?</h2>
        <ul>
          <li>
            <strong>Expertiză Tehnică:</strong> Echipa noastră este formată din specialiști cu experiență vastă în cadastru și topografie.
          </li>
          <li>
            <strong>Cunoaștere Locală:</strong> Activăm în județul Prahova și suntem familiarizați cu toate reglementările legale locale.
          </li>
          <li>
            <strong>Servicii Eficiente:</strong> Asigurăm întocmirea corectă și rapidă a documentației de dezmembrare.
          </li>
          <li>
            <strong>Consultanță Personalizată:</strong> Te ghidăm pe tot parcursul procesului, oferindu-ți soluții clare și precise.
          </li>
        </ul>
      </div>
    </section>
    <ContactMap text="Pentru servicii de dezmembrare rapide și profesionale, contactează echipa Topolex. Suntem partenerul tău de încredere în Vălenii de Munte și județul Prahova." />
  </>
);

export default DocumentatieDeDezmembrare;
