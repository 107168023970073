import React from "react";
import { NavLink } from "react-router-dom";
import "./footer.module.scss";

const Footer = () => (
  <footer className="footer">
    <div className="container">
      {/* Footer Top Section */}
      <div className="footer-top">
        <div className="ft-1">
          <NavLink to="/">
            <img
              src="./assets/logo-white.webp"
              alt="Topolex Logo"
              width="130"
              className="logo"
            />
          </NavLink>
        </div>
        <div className="ft-2">
          <p>
            JUD. PRAHOVA, Vălenii de Munte,
            <br />
            bld Nicolae Iorga nr 133
          </p>
          <div className="contact">
            <h4>Tel:</h4>
            <p>
              <a href="tel:+0244280534">0244 280 534</a>
              <br />
              <a href="tel:+0761776666">0761 776 666</a>
            </p>
          </div>
          <div className="email">
            <h4>Email:</h4>
            <p>
              <a href="mailto:bogdan_onoiu2000@yahoo.com">
                bogdan_onoiu2000@yahoo.com
              </a>
              <br />
              <a href="mailto:officetopolex@yahoo.com">
                officetopolex@yahoo.com
              </a>
            </p>
          </div>
        </div>
        <div className="ft-3">
          <p>
            <NavLink to="/politica-confidentialitate">
              Politica de Confidențialitate
            </NavLink>
          </p>
          <p>
            <NavLink to="/politica-cookie-uri">
              Politica de Cookie-uri
            </NavLink>
          </p>
          <a href="#">
            <img
              src="./assets/footer-img.webp"
              alt="Footer Branding"
              className="footer-image"
            />
          </a>
        </div>
        <ul className="ft-4">
          <li>
            <NavLink to="/documentatie-intabulare" className="ft-item">
              Documentație intabulare
            </NavLink>
          </li>
          <li>
            <NavLink to="/documentatie-atribuire-numar-cadastral" className="ft-item">
              Documentație atribuire număr cadastral
            </NavLink>
          </li>
          <li>
            <NavLink to="/documentatie-de-dezmembrare" className="ft-item">
              Documentație de dezmembrare
            </NavLink>
          </li>
          <li>
            <NavLink to="/despre-noi" className="ft-item">
              Despre noi
            </NavLink>
          </li>
          <li>
            <NavLink to="/proiecte" className="ft-item">
              Proiectele noastre
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" className="ft-item">
              Contact
            </NavLink>
          </li>
        </ul>
      </div>

      {/* Footer Bottom Section */}
      <div className="footer-bottom">
      <p>
    Copyright ©2024 Topolex. Realizat de{" "}
    <a
      href="https://digitalhustle.ro/ro/"
      target="_blank"
      rel="noopener noreferrer"
      className="digital-hustle-link"
    >
      Digital Hustle
    </a>
  </p>
        <div className="footer-social">
          <a href="#">
            <img src="./assets/fb.webp" alt="Facebook" height="20" />
          </a>
          <a href="#">
            <img src="./assets/in.png" alt="LinkedIn" height="20" />
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
