import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="nav-wrapper">
      <div className="nav-container container">
        <NavLink to="/" className="nav-logo" onClick={() => setMenuOpen(false)}>
          <img src="./assets/logo.webp" alt="Logo" height="75" />
        </NavLink>
        <div
          className={`hamburger ${menuOpen ? "active" : ""}`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul className={`nav-list ${menuOpen ? "active" : ""}`}>
          <li className="nav-item">
            <NavLink
              to="/"
              className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`}
              onClick={() => setMenuOpen(false)}
            >
              Acasă
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/servicii"
              className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`}
              onClick={() => setMenuOpen(false)}
            >
              Servicii
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/despre-noi"
              className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`}
              onClick={() => setMenuOpen(false)}
            >
              Despre noi
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/proiecte"
              className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`}
              onClick={() => setMenuOpen(false)}
            >
              Proiecte
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/contact"
              className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`}
              onClick={() => setMenuOpen(false)}
            >
              Contact
            </NavLink>
          </li>
          <li className="nav-item">
            <a href="tel:0761776666" className="nav-link">
              <img src="./assets/call.png" alt="Call" height="30" width="30" />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
