import React from "react";
import { Helmet } from "react-helmet";

const CookiePolicy = () => (
  <>
    <Helmet>
      <title>Politica de Cookie-uri - MASTER TOPO LEX SRL</title>
      <meta
        name="description"
        content="Află informații detaliate despre politica de utilizare a cookie-urilor pe site-ul MASTER TOPO LEX SRL. Descoperă cum utilizăm cookie-urile pentru a îmbunătăți experiența utilizatorilor și cum îți poți gestiona preferințele."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta
        name="keywords"
        content="politica cookie-uri, MASTER TOPO LEX SRL, utilizare cookie-uri, preferințe cookie, confidențialitate site"
      />
      <meta name="author" content="MASTER TOPO LEX SRL" />

      {/* Open Graph Meta Tags */}
      <meta
        property="og:title"
        content="Politica de Cookie-uri - MASTER TOPO LEX SRL"
      />
      <meta
        property="og:description"
        content="Politica de utilizare a cookie-urilor pe site-ul MASTER TOPO LEX SRL. Înțelege modul în care sunt utilizate cookie-urile și cum poți seta preferințele tale."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://topolex.ro/politica-cookie-uri" />
      <meta property="og:image" content="https://topolex.ro/assets/banner.webp" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Politica de Cookie-uri - MASTER TOPO LEX SRL"
      />
      <meta
        name="twitter:description"
        content="Află cum utilizăm cookie-urile pe site-ul MASTER TOPO LEX SRL și cum îți poți gestiona preferințele pentru o experiență personalizată."
      />
      <meta name="twitter:image" content="https://topolex.ro/assets/banner.webp" />
      <link rel="canonical" href="https://topolex.ro/politica-cookie-uri" />
    </Helmet>

    <section className="cookie-policy">
      <div className="container">
        <h1>Politica de Cookie-uri</h1>
        <p>
          Această politică de cookie-uri explică ce sunt cookie-urile, cum le utilizăm pe site-ul nostru și cum poți gestiona preferințele tale legate de acestea.
        </p>

        <h2>1. Ce sunt cookie-urile?</h2>
        <p>
          Cookie-urile sunt fișiere mici stocate pe dispozitivul tău (computer, tabletă, smartphone) atunci când vizitezi un site web. Acestea permit site-ului să-și amintească acțiunile și preferințele tale (cum ar fi limba, dimensiunea fontului și alte preferințe de afișare) pentru o perioadă de timp.
        </p>

        <h2>2. Cum folosim cookie-urile?</h2>
        <p>Site-ul nostru utilizează cookie-uri pentru următoarele scopuri:</p>
        <ul>
          <li>
            <strong>Cookie-uri esențiale:</strong> Acestea sunt necesare pentru funcționarea corectă a site-ului nostru. Fără ele, anumite funcționalități nu ar putea fi utilizate.
          </li>
          <li>
            <strong>Cookie-uri de performanță:</strong> Utilizăm Google Analytics pentru a analiza modul în care vizitatorii folosesc site-ul și pentru a îmbunătăți experiența utilizatorilor.
          </li>
          <li>
            <strong>Cookie-uri funcționale:</strong> Acestea permit site-ului să-și amintească alegerile tale, cum ar fi limba preferată sau locația.
          </li>
        </ul>

        <h2>3. Cookie-uri terțe</h2>
        <p>
          Pe site-ul nostru pot fi utilizate și cookie-uri ale unor terțe părți, cum ar fi cele furnizate de Google Analytics. Acestea colectează informații anonime despre utilizarea site-ului.
        </p>

        <h2>4. Cum poți gestiona cookie-urile?</h2>
        <p>
          Poți controla și/sau șterge cookie-urile după preferințe. Majoritatea browserelor web permit configurarea preferințelor privind cookie-urile. Poți dezactiva toate cookie-urile sau doar pe cele ale unor site-uri specifice. Totuși, dezactivarea cookie-urilor poate afecta funcționalitatea site-ului nostru.
        </p>
        <p>
          Pentru mai multe informații despre gestionarea cookie-urilor, vizitează site-ul{" "}
          <a
            href="https://www.aboutcookies.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            AboutCookies.org
          </a>
          .
        </p>

        <h2>5. Modificări ale politicii de cookie-uri</h2>
        <p>
          Ne rezervăm dreptul de a actualiza această politică de cookie-uri în orice moment. Vă recomandăm să verificați periodic această pagină pentru a fi la curent cu eventualele modificări.
        </p>

        <h2>6. Contact</h2>
        <p>
          Dacă ai întrebări legate de politica noastră de cookie-uri, ne poți contacta la:
        </p>
        <ul>
          <li><strong>MASTER TOPO LEX SRL</strong></li>
          <li>Județul Prahova, Vălenii de Munte, Bld. Nicolae Iorga, Nr. 133</li>
          <li>Email: <a href="mailto:officetopolex@yahoo.com">officetopolex@yahoo.com</a></li>
          <li>Telefon: <a href="tel:0761776666">0761 776 666</a></li>
        </ul>
      </div>
    </section>
  </>
);

export default CookiePolicy;
