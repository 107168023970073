import React from "react";
import PropTypes from "prop-types";

const ContactMap = ({ text = ""}) => (
  <section id="contact-map">
    <div className="map-content">
        <p>Contactează-ne!</p>
        <p>{text}</p>
        <a href="tel:0761776666" className="banner-cont-number">
                0761 776 666
              </a>
    </div>
  </section>
);
ContactMap.propTypes = {
    text: PropTypes.string,
}
export default ContactMap;
