import React from "react";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => (
  <>
    <Helmet>
      <title>Politica de Confidențialitate - MASTER TOPO LEX SRL</title>
      <meta
        name="description"
        content="Citește politica de confidențialitate a MASTER TOPO LEX SRL pentru a înțelege modul în care colectăm, utilizăm și protejăm datele tale personale în conformitate cu reglementările GDPR."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta
        name="keywords"
        content="politica de confidențialitate, MASTER TOPO LEX SRL, protecția datelor, GDPR, utilizare date personale"
      />
      <meta name="author" content="MASTER TOPO LEX SRL" />

      {/* Open Graph Meta Tags */}
      <meta
        property="og:title"
        content="Politica de Confidențialitate - MASTER TOPO LEX SRL"
      />
      <meta
        property="og:description"
        content="Politica de confidențialitate a MASTER TOPO LEX SRL explică modul în care colectăm și protejăm datele tale personale conform normelor GDPR."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://topolex.ro/politica-confidențialitate"
      />
      <meta property="og:image" content="https://topolex.ro/assets/banner.webp" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Politica de Confidențialitate - MASTER TOPO LEX SRL"
      />
      <meta
        name="twitter:description"
        content="Descoperă politica de confidențialitate a MASTER TOPO LEX SRL. Află cum sunt gestionate și protejate datele tale personale."
      />
      <meta name="twitter:image" content="https://topolex.ro/assets/banner.webp" />
      <link rel="canonical" href="https://topolex.ro/politica-confidențialitate" />
    </Helmet>

    <section className="privacy-policy">
      <div className="container">
        <h1>Politica de Confidențialitate</h1>
        <p>
          MASTER TOPO LEX SRL respectă confidențialitatea datelor dumneavoastră și se angajează să protejeze informațiile personale pe care le colectăm. Această politică explică modul în care colectăm, utilizăm și protejăm datele personale.
        </p>

        <h2>1. Informații colectate</h2>
        <p>
          Putem colecta următoarele tipuri de informații personale:
        </p>
        <ul>
          <li>Nume și prenume;</li>
          <li>Adresa de email;</li>
          <li>Numărul de telefon;</li>
          <li>Adresa IP și informații despre dispozitivul utilizat;</li>
          <li>Date colectate prin Google Analytics și Search Console.</li>
        </ul>

        <h2>2. Utilizarea informațiilor</h2>
        <p>
          Datele colectate sunt utilizate în scopuri precum:
        </p>
        <ul>
          <li>Furnizarea serviciilor solicitate de dumneavoastră;</li>
          <li>Comunicarea cu clienții noștri;</li>
          <li>Îmbunătățirea serviciilor noastre prin analiza traficului și comportamentului utilizatorilor;</li>
          <li>Respectarea obligațiilor legale și fiscale.</li>
        </ul>

        <h2>3. Utilizarea cookie-urilor</h2>
        <p>
          Acest site utilizează cookie-uri pentru a îmbunătăți experiența utilizatorului și pentru a analiza traficul. Cookie-urile pot fi utilizate de servicii precum Google Analytics pentru a înțelege cum interacționează utilizatorii cu site-ul nostru.
        </p>
        <p>
          Puteți dezactiva cookie-urile din setările browserului dumneavoastră. Totuși, unele funcționalități ale site-ului pot fi afectate.
        </p>

        <h2>4. Protecția datelor</h2>
        <p>
          MASTER TOPO LEX SRL implementează măsuri tehnice și organizaționale adecvate pentru a proteja datele personale împotriva accesului neautorizat, pierderii sau distrugerii. Doar personalul autorizat are acces la datele dumneavoastră.
        </p>

        <h2>5. Drepturile dumneavoastră</h2>
        <p>
          Aveți următoarele drepturi privind datele dumneavoastră personale:
        </p>
        <ul>
          <li>Dreptul de acces la datele personale;</li>
          <li>Dreptul de rectificare a datelor personale;</li>
          <li>Dreptul de ștergere ("dreptul de a fi uitat");</li>
          <li>Dreptul de restricționare a procesării;</li>
          <li>Dreptul de opoziție;</li>
          <li>Dreptul de portabilitate a datelor.</li>
        </ul>

        <h2>6. Contact</h2>
        <p>
          Dacă aveți întrebări sau solicitări referitoare la datele dumneavoastră personale, ne puteți contacta la:
        </p>
        <ul>
          <li><strong>MASTER TOPO LEX SRL</strong></li>
          <li>Județul Prahova, Vălenii de Munte, Bld. Nicolae Iorga, Nr. 133</li>
          <li>Email: <a href="mailto:officetopolex@yahoo.com">officetopolex@yahoo.com</a></li>
          <li>Telefon: <a href="tel:0761776666">0761 776 666</a></li>
        </ul>

        <p>
          Această politică de confidențialitate a fost actualizată ultima dată la [12.12.2024].
        </p>
      </div>
    </section>
  </>
);

export default PrivacyPolicy;
