import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import Home from "./pages/home";
import Services from "./pages/services";
import About from "./pages/about";
import Projects from "./pages/projects";
import Contact from "./pages/contact";
import ScrollToTop from "./components/scrollTop/scrollTop";
import DocumentatieIntabulare from "./pages/documentatie-intabulare";
import DocumentatieAtribuireNumarCadastral from "./pages/DocumentatieAtribuireNumarCadastral";
import DocumentatieDeDezmembrare from "./pages/DocumentatieDeDezmembrare";
import CookieConsent from "./components/cookie-consent/cookieConsent";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";

const App = () => {
  const basename = process.env.NODE_ENV === "production" ? "/" : "/";

  return (
    <Router basename={basename}>
      <ScrollToTop />
      <Navbar />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/servicii" element={<Services />} />
          <Route path="/despre-noi" element={<About />} />
          <Route path="/proiecte" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/documentatie-intabulare" element={<DocumentatieIntabulare />} />
          <Route path="/documentatie-atribuire-numar-cadastral" element={<DocumentatieAtribuireNumarCadastral />} />
          <Route path="/documentatie-de-dezmembrare" element={<DocumentatieDeDezmembrare />} />
          <Route path="/politica-confidentialitate" element={<PrivacyPolicy />} />
          <Route path="/politica-cookie-uri" element={<CookiePolicy />} />
        </Routes>
      </main>
      <Footer />
      <CookieConsent/>
    </Router>
  );
};

export default App;
