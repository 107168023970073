import React from "react";

const About = () => (
  <section id="onu">
    <div className="onu-container container">
      <div className="image-container">
        <img src="./assets/onu.webp" alt="onu" />
      </div>
      <div className="onu-content">
        <h4>TOPOLEX</h4>
        <h5>FIRMĂ CADASTRU ȘI INTABULARE - Vălenii de Munte</h5>
        <p>
          Echipa noastră este formată din specialiști cu experiență de peste 25
          ani în domeniul cadastrului și al topografiei.
        </p>
      </div>
    </div>
  </section>
);

export default About;
