import React from "react";
import ServiciuTop from "../components/serviciu-top/serviciu-top";
import ContactMap from "../components/contact-map/contact-map";
import { Helmet } from "react-helmet";


const DocumentatieIntabulare = () => (
  <>
    <Helmet>
      <title>Documentație Intabulare | Topolex - Siguranță și Legalitate pentru Proprietatea Ta</title>
      <meta
        name="description"
        content="Topolex oferă servicii complete pentru documentația de intabulare. Îți garantăm siguranța și claritatea juridică a drepturilor de proprietate în Vălenii de Munte și județul Prahova."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta
        name="keywords"
        content="documentație intabulare, intabulare Prahova, cadastru și topografie Vălenii de Munte, drepturi de proprietate, siguranță juridică"
      />
      <meta name="author" content="Topolex" />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content="Documentație Intabulare | Topolex - Siguranță și Legalitate pentru Proprietatea Ta" />
      <meta
        property="og:description"
        content="Îți oferim servicii profesionale pentru documentația de intabulare. Apelează la Topolex pentru soluții rapide și eficiente în județul Prahova."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://topolex.ro/documentatie-intabulare" />
      <meta property="og:image" content="https://topolex.ro/assets/banner.webp" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Documentație Intabulare | Topolex - Siguranță și Legalitate pentru Proprietatea Ta" />
      <meta
        name="twitter:description"
        content="Topolex este partenerul tău de încredere pentru servicii complete de documentație intabulare în județul Prahova."
      />
      <meta name="twitter:image" content="https://topolex.ro/assets/banner.webp" />
      <link rel="canonical" href="https://topolex.ro/documentatie-intabulare" />
    </Helmet>

    <ServiciuTop
      title="Documentație Intabulare - Servicii Profesionale în Vălenii de Munte, Prahova"
      text="La Topolex, oferim servicii complete de intabulare, esențiale pentru înregistrarea 
    corectă și legală a proprietăților imobiliare în Cartea Funciară. Fie că ești proprietar, 
    dezvoltator imobiliar sau investitor, echipa noastră de experți în cadastru și topografie 
    din Vălenii de Munte te sprijină în toate etapele procesului de intabulare."
    />
    <section className="serviciu-content">
      <div className="container">
        <h2>Ce este intabularea?</h2>
        <p>
          Intabularea reprezintă procesul de înscriere definitivă a dreptului de proprietate asupra unui imobil în Cartea Funciară, oferind astfel siguranță și claritate juridică. Este un pas obligatoriu pentru a garanta drepturile de proprietate și pentru a putea efectua operațiuni legale precum vânzarea, ipotecarea sau succesiunea unui bun imobil.
        </p>

        <h2>De ce să alegi Topolex?</h2>
        <ul>
          <li>
            <strong>Experiență Locală:</strong> Activăm în județul Prahova și cunoaștem în detaliu reglementările și particularitățile zonei.
          </li>
          <li>
            <strong>Echipă Certificată:</strong> Specialiștii noștri sunt acreditați și au o vastă experiență în domeniul cadastrului și topografiei.
          </li>
          <li>
            <strong>Servicii Rapide și Eficiente:</strong> Ne asigurăm că documentația ta este întocmită corect și livrată în cel mai scurt timp posibil.
          </li>
          <li>
            <strong>Consultanță Personalizată:</strong> Te ghidăm pas cu pas pe parcursul întregului proces de intabulare, oferindu-ți informații clare și precise.
          </li>
        </ul>
      </div>
    </section>
    <ContactMap text="Dacă ai nevoie de servicii de intabulare rapide și profesionale, contactează-ne astăzi! Topolex este partenerul tău de încredere în județul Prahova și nu numai." />
  </>
);

export default DocumentatieIntabulare;
