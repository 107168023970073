import React, { useEffect } from "react";
import PropTypes from "prop-types";

const Banner = ({ className = "", title = "Default Title", showPhone = true }) => {
  const handleScroll = () => {
    const nextSection = document.querySelector("#banner-section + *");
    if (nextSection) {
      const headerHeight =
        window.innerWidth <= 768
          ? 70 // Mobile header height
          : 114; // Desktop header height
      const offsetTop = nextSection.offsetTop;
      window.scrollTo({
        top: offsetTop - headerHeight,
        behavior: "smooth",
      });
    }
  };

  const handleWheel = (e) => {
    if (e.deltaY > 0) {
      handleScroll();
    }
  };

  useEffect(() => {
    const banner = document.getElementById("banner-section");
    banner.addEventListener("wheel", handleWheel);

    return () => {
      banner.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <section id="banner-section" className={`${className} home-banner`}>
      <div className="banner-left">
        <div className="container">
          <div className="banner-cont">
            <h2>{title}</h2>
            {showPhone && (
              <a href="tel:0761776666" className="banner-cont-number">
                0761 776 666
              </a>
            )}
            <button className="scroll-arrow" onClick={handleScroll}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

Banner.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  showPhone: PropTypes.bool,
};

export default Banner;
