import React from "react";
import "./we-do.module.scss";

const WeDo = () => (
  <section id="we-do" className="we-do">
    <div className="container we-do-content">
      <h2>CE FACEM:</h2>
      <ul className="do-list">
        <li>
          Executăm documentații de cadastru și intabulare pentru apartamente,
          terenuri, construcții noi;
        </li>
        <li>
          Întocmim documentații de dezmembrări și alipiri terenuri,
          apartamentări și reapartamentări construcții;
        </li>
        <li>
          Realizăm cadastre de specialitate: cadastru agricol, cadastrul fondului
          apelor, cadastru forestier, cadastru edilitar;
        </li>
        <li>
          Efectuăm ridicări topografice, planuri topografice pentru aviz tehnic,
          PUZ, PUD și proiectare, profile longitudinale și transversale, calcul
          volum de săpătură și umplutură pentru șantiere;
        </li>
        <li>
          Executăm lucrări de trasare a axelor construcțiilor de locuințe cât și
          industriale, efectuăm măsurători pentru înțărușarea terenurilor;
        </li>
        <li>
          Executăm ridicări topografice pentru realizarea modelului 3D al
          terenului și efectuăm lucrări de urmărire a comportării în timp a
          construcțiilor.
        </li>
      </ul>
    </div>
  </section>
);

export default WeDo;
