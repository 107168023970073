import React from "react";
import Banner from "../components/banner/banner";
import MapSection from "../components/map-section/map-section";
import { Helmet } from "react-helmet";

const About = () => (
  <>
    <Helmet>
      <title>Despre Noi - Topolex | Expertiză în Cadastru și Topografie</title>
      <meta
        name="description"
        content="Află mai multe despre echipa Topolex, cu peste 17 ani de experiență în cadastru și topografie. Soluții moderne și profesionale pentru proprietățile tale."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="keywords" content="despre Topolex, experiență cadastru, topografie Prahova, echipă profesională, tehnologie cadastrală modernă" />
      <meta name="author" content="Topolex" />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content="Despre Noi - Topolex | Expertiză în Cadastru și Topografie" />
      <meta
        property="og:description"
        content="Topolex oferă servicii moderne și profesionale de cadastru și topografie, având o echipă dedicată cu peste 17 ani de experiență."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://topolex.ro/despre-noi" />
      <meta property="og:image" content="https://topolex.ro/assets/banner.webp" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Despre Noi - Topolex | Expertiză în Cadastru și Topografie" />
      <meta
        name="twitter:description"
        content="Descoperă profesionalismul echipei Topolex, lideri în cadastru și topografie din județul Prahova."
      />
      <meta name="twitter:image" content="https://topolex.ro/assets/banner.webp" />
      <link rel="canonical" href="https://topolex.ro/despre-noi" />
    </Helmet>

    <Banner
      className="about-banner"
      title="Cine Suntem și Ce Oferim - Profesionalism și Dedicație în Servicii Cadastrale"
      showPhone={false}
    />
    <section id="about-us" class="about-us-section">
      <div class="container">
        <h2>Experți în Cadastru și Topografie</h2>
        <p>
          Înființată în anul 2008, firma noastră de cadastru a devenit un nume de
          referință în domeniu, având o experiență de 17 ani care ne permite să
          oferim servicii de înaltă calitate și soluții personalizate pentru clienții
          noștri. De-a lungul anilor, am construit o echipă de specialiști dedicați,
          care combină expertiza tehnică cu o înțelegere profundă a nevoilor
          clienților.
        </p>
        <p>
          Ne concentrăm pe furnizarea de servicii cadastrale complete, inclusiv
          măsurători topografice, întocmirea de planuri cadastrale, asistență în
          procesul de înregistrare a proprietăților și consultanță în domeniul
          legislației cadastrale. Fiecare proiect este tratat cu atenție și
          profesionalism, având ca obiectiv final satisfacția clientului.
        </p>
        <p>
          În plus, suntem angajați în dezvoltarea comunității, contribuind la
          proiecte locale și sprijinind inițiativele de dezvoltare durabilă. Credem
          că un cadastru bine gestionat este esențial pentru o dezvoltare economică
          sănătoasă și pentru îmbunătățirea calității vieții. Așadar, alegându-ne pe
          noi, nu doar că beneficiați de servicii de top, dar și de un partener de
          încredere în procesul de dezvoltare a proprietăților dumneavoastră.
        </p>

        <h2>Tehnologie Avansată</h2>
        <p>
          Firma noastră de cadastru dispune de o gamă variată de aparatură de înaltă
          tehnologie, esențială pentru realizarea serviciilor noastre de cadastru și
          topografie. Printre echipamentele noastre se numără stații totale de ultimă
          generație, care ne permit să efectuăm măsurători precise și eficiente.
        </p>
        <p>
          De asemenea, utilizăm drone pentru a captura imagini aeriene detaliate,
          facilitând astfel analiza terenurilor și realizarea planurilor cadastrale.
          Receptoarele GPS de înaltă precizie sunt folosite pentru a asigura o
          localizare exactă a punctelor de interes, în timp ce distomatele ne ajută să
          măsurăm distanțele cu o acuratețe deosebită.
        </p>
        <p>
          În plus, folosim softuri dedicate care optimizează procesul de prelucrare a
          datelor și ne ajută să generăm rapoarte și planuri cadastrale conforme cu
          standardele legale.
        </p>
        <p>
          Această combinație de tehnologie avansată și expertiză profesională ne
          permite să oferim servicii de calitate superioară, adaptate nevoilor
          clienților noștri și contribuind la dezvoltarea eficientă a proprietăților.
        </p>
      </div>
    </section>

    <MapSection />
  </>
);

export default About;
