import React from "react";
import "./servicii-full.module.scss";

const ServiciiFull = () => (
  <section id="servicii-full" className="servicii-full">
    <div className="container we-do-content">
      <div class="columns-container">
        <div class="column">
          <ul>
            <li>Documentatie intabulare</li>
            <li>Documentatie atribuire numar cadastral</li>
            <li>Documentatie de dezmembrare</li>
            <li>Documentatie alipire</li>
            <li>Documentatie apartamentare constructii</li>
            <li>Suport topo(pac)</li>
            <li>Documentatie rectificare limita si suprafata imobil</li>
            <li>Documentatie actualizare date tehnice</li>
            <li>Documentatie extindere/radiere constructie</li>
            <li>Documentatie inscriere constructie</li>
            <li>Documentatie repozitionare</li>
            <li>Documentatie avizare expertiza</li>
          </ul>
        </div>
        <div class="column">
          <ul>
            <li>Documentatie pentru obtinerea titlului de proprietate</li>
            <li>Documentatie pentru obtinerea titlului de proprietate+incadrare in tarla</li>
            <li>Documentatie intabulare + incadrare in tarla</li>
            <li>Extrase de carte funciara actualizate</li>
            <li>Planuri topografice pentru aviz tehnic, puz, pud si proiectare, profile longitudinale si transversale</li>
            <li>Calcul volum de sapatura si umplutura pentru santiere, balastiere, rampa ecologica</li>
            <li>Trasare a axelor constructiilor de locuinte, cat si industriale</li>
            <li>Masuratori pentru intarusarea terenurilor</li>
            <li>Realizam cadastru general/sistematic</li>
          </ul>
        </div>
      </div>

    </div>
  </section>
);

export default ServiciiFull;
