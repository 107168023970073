import React from "react";
import Banner from "../components/banner/banner";
import MapSection from "../components/map-section/map-section";
import { Helmet } from "react-helmet";

const Contact = () => (
  <>
    <Helmet>
      <title>Contact - Topolex | Soluții Cadastrale și Topografice în Prahova</title>
      <meta
        name="description"
        content="Ia legătura cu echipa Topolex pentru servicii profesionale de cadastru și topografie. Suntem pregătiți să răspundem nevoilor tale în județul Prahova."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="keywords" content="contact Topolex, servicii cadastru Prahova, topografie Vălenii de Munte, contact servicii cadastrale" />
      <meta name="author" content="Topolex" />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content="Contact - Topolex | Soluții Cadastrale și Topografice în Prahova" />
      <meta
        property="og:description"
        content="Contactează Topolex pentru servicii rapide și eficiente de cadastru și topografie. Echipa noastră din Prahova îți stă la dispoziție."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://topolex.ro/contact" />
      <meta property="og:image" content="https://topolex.ro/assets/banner.webp" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Contact - Topolex | Soluții Cadastrale și Topografice în Prahova" />
      <meta
        name="twitter:description"
        content="Suntem aici pentru a te ajuta cu servicii profesionale de cadastru și topografie în județul Prahova. Contactează-ne acum!"
      />
      <meta name="twitter:image" content="https://topolex.ro/assets/banner.webp" />
      <link rel="canonical" href="https://topolex.ro/contact" />
    </Helmet>

    <Banner
      className="contact-banner"
      title="Contact Topolex - Suntem Aici pentru a te Ajuta cu Servicii Cadastrale Profesionale"
      showPhone={false}
    />
    <section id="contact" className="contact-section">
      <div className="container">
        <h1>Hai să vorbim</h1>
        <div className="contact-details">
          <p>
            <strong>Adresa:</strong><br />
            JUD. PRAHOVA, Vălenii de Munte,<br />
            bld Nicolae Iorga nr 133
          </p>
          <p>
            <strong>Telefon:</strong><br />
            <a href="tel:+40244280534">0244 280 534</a><br />
            <a href="tel:+40761776666">0761 776 666</a>
          </p>
          <p>
            <strong>Email:</strong><br />
            <a href="mailto:bogdan_onoiu2000@yahoo.com">bogdan_onoiu2000@yahoo.com</a><br />
            <a href="mailto:officetopolex@yahoo.com">officetopolex@yahoo.com</a>
          </p>
        </div>
        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2841025.742033541!2d25.020108399999998!3d45.9425072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2730caa957b90a4d%3A0x8a80c0389752c1ec!2sTopolex!5e0!3m2!1sen!2sro!4v1734073696889!5m2!1sen!2sro"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </section>

    <MapSection />
  </>
);

export default Contact;
