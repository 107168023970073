import React from "react";
import { Link } from "react-router-dom";

const Services = () => (
  <section id="service">
    <div className="container">
      <h2>SERVICII</h2>
      <div className="service-items">
        <div className="service-item">
          <img src="./assets/service1.jpg" alt="service" />
          <Link to="/documentatie-intabulare">Documentatie intabulare</Link>
        </div>
        <div className="service-item">
          <img src="./assets/service2.webp" alt="service" />
          <Link to="/documentatie-atribuire-numar-cadastral">Documentatie atribuire numar cadastral</Link>
        </div>
        <div className="service-item">
          <img src="./assets/dezmembrare.webp" alt="service" />
          <Link to="/documentatie-dezmembrare">Documentatie de dezmembrare</Link>
        </div>
      </div> 
      <Link to="/services" className="more-btn">
  Mai MULTE Servicii
</Link>
    </div>
  </section>
);

export default Services;
