import React from "react";
import Banner from "../components/banner/banner";
import About from "../components/about/about";
import Services from "../components/services/services";
import MapSection from "../components/map-section/map-section";
import { Helmet } from "react-helmet";
import WeDo from "../components/we-do/we-do";

const Home = () => (
  <>
    <Helmet>
      <title>Topolex - Servicii Profesionale de Cadastru și Topografie în Prahova</title>
      <meta
        name="description"
        content="Topolex oferă servicii complete și profesionale de cadastru și topografie în județul Prahova. Soluții rapide, personalizate și eficiente."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="keywords" content="servicii cadastru, topografie Prahova, intabulare, dezmembrare, cadastru Vălenii de Munte, cadastru terenuri, cadastru construcții" />
      <meta name="author" content="Topolex" />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content="Topolex - Servicii Profesionale de Cadastru și Topografie în Prahova" />
      <meta
        property="og:description"
        content="Apelând la Topolex, beneficiezi de servicii profesionale și rapide de cadastru și topografie, adaptate nevoilor tale."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://topolex.ro/" />
      <meta property="og:image" content="https://topolex.ro/assets/banner.webp" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Topolex - Servicii Profesionale de Cadastru și Topografie în Prahova" />
      <meta
        name="twitter:description"
        content="Topolex oferă soluții complete și profesionale de cadastru și topografie, în județul Prahova."
      />
      <meta name="twitter:image" content="https://topolex.ro/assets/banner.webp" />
      <link rel="canonical" href="https://topolex.ro/" />
    </Helmet>

    <Banner
      className="home-banner"
      title="Lucrări cadastru și topografie în județul Prahova și nu numai"
    />
    <About />
    <WeDo />
    <Services />
    <MapSection />
  </>
);

export default Home;
