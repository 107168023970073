import React from "react";
import PropTypes from "prop-types";

const ServiciuTop = ({ className = "", title = "Default Title", showPhone = true, text ="default text" }) => {
  
  return (
    <section id="serviciu-top-section" className={`${className}`}>
      <div className="banner-left">
        <div className="container">
          <div className="banner-cont">
            <h1>{title}</h1>
            {showPhone && (
              <a href="tel:0761776666" className="banner-cont-number">
                0761 776 666
              </a>
            )}
            <p>{text}</p>
            <img src="./assets/t.webp" alt="topograf" height="100%" />
          </div>
        </div>
      </div>
    </section>
  );
};

ServiciuTop.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  showPhone: PropTypes.bool,
  text: PropTypes.string,
};

export default ServiciuTop;
