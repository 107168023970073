import React, { useState } from "react";
import "./cookieConsent.module.scss";

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setIsVisible(false);
  };

  const handleLearnMore = () => {
    window.open("https://policies.google.com/privacy", "_blank");
  };

  if (localStorage.getItem("cookieConsent") === "true") {
    return null;
  }

  return (
    isVisible && (
      <div className="cookie-consent">
        <div className="cookie-message">
          <p>
            Folosim cookie-uri pentru a îmbunătăți experiența ta și pentru a analiza traficul, prin Google Analytics și Google Search Console. Continuând să navighezi, accepți utilizarea acestora.
          </p>
          <div className="cookie-actions">
            <button className="accept-btn" onClick={handleAccept}>
              Accept
            </button>
            <button className="learn-more-btn" onClick={handleLearnMore}>
              Află mai multe
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default CookieConsent;
