import React from "react";
import Banner from "../components/banner/banner";
import MapSection from "../components/map-section/map-section";
import { Helmet } from "react-helmet";

const Projects = () => (
  <>
    <Helmet>
      <title>Proiectele Noastre - Topolex, Lucrări Cadastrale Prahova</title>
      <meta
        name="description"
        content="Descoperă proiectele noastre de cadastru și topografie realizate în județul Prahova. Topolex - Profesionalism și experiență în domeniu."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta
        name="keywords"
        content="proiecte cadastru Prahova, lucrări topografie, Topolex proiecte, cadastru Vălenii de Munte"
      />
      <meta name="author" content="Topolex" />

      {/* Open Graph Meta Tags */}
      <meta
        property="og:title"
        content="Proiectele Noastre - Topolex, Lucrări Cadastrale Prahova"
      />
      <meta
        property="og:description"
        content="Vizitează proiectele noastre de succes în domeniul cadastrului și topografiei. Profesionalism și soluții eficiente pentru orice proprietate."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://topolex.ro/proiecte" />
      <meta property="og:image" content="https://topolex.ro/assets/banner.webp" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Proiectele Noastre - Topolex, Lucrări Cadastrale Prahova"
      />
      <meta
        name="twitter:description"
        content="Descoperă proiectele realizate de Topolex în județul Prahova. Servicii profesionale de cadastru și topografie."
      />
      <meta name="twitter:image" content="https://topolex.ro/assets/banner.webp" />
      <link rel="canonical" href="https://topolex.ro/proiecte" />
    </Helmet>

    <Banner
      className="servicii-banner"
      title="Proiectele Noastre - Experiența și Profesionalismul Topolex în Fiecare Detaliu"
      showPhone={false}
    />
    <section id="about-us" class="about-us-section">
      <div class="container">
        <p>
          De-a lungul celor 17 ani de activitate, firma noastră a avut privilegiul de
          a colabora la o varietate de proiecte importante în domeniul cadastrului și
          topografiei. Experiența acumulată ne permite să abordăm cu succes orice
          provocare, indiferent de complexitatea acesteia.
        </p>
        <p>
          Echipa noastră a contribuit la realizarea de documentații pentru terenuri,
          construcții rezidențiale și industriale, planuri topografice și alte
          servicii esențiale pentru dezvoltarea și gestionarea eficientă a
          proprietăților. Fiecare proiect este tratat cu profesionalism și atenție la
          detalii, având ca obiectiv satisfacția clienților noștri.
        </p>
        <p>
          Continuăm să ne dezvoltăm și să investim în tehnologie avansată pentru a
          livra rezultate de calitate superioară, contribuind astfel la succesul
          proiectelor clienților noștri.
        </p>

      </div>
    </section>

    <MapSection />
  </>
);

export default Projects;
