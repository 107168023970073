import React from "react";

const MapSection = () => (
  <section id="map">
    <div className="map">
      <img src="./assets/map.webp" alt="map" />
    </div>
  </section>
);

export default MapSection;
